import { AxiosError } from 'axios'
import isEqual from 'lodash.isequal'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

import { JaarPeriode, OefenenPeriode, ToetsenPeriode } from '@/enums/Timespan'
import { UserType } from '@/enums/UserType'
import { Yeargroup } from '@/enums/Yeargroup'
import { UpvotyUser, createUpvotyToken } from '@/helpers/upvotySSO'
import { IUser } from '@/interfaces/IUser'
import { IHomepageWidgetsBlurred, IUserSettings } from '@/interfaces/IUserSettings'
import { onboardingService } from '@/services/onboardingService'
import { settingsService } from '@/services/settingsService'
import { userService } from '@/services/userService'
import { notify } from '@kyvg/vue3-notification'

class UserInfoState {
    currentUser: IUser = {
        voornaam: '',
        achternaam: '',
        tussenvoegsel: '',
        email: '',
        id: -1,
        jaargroep: null,
        type: UserType.Geen,
        onboardingAfgerond: false,
    }
    isLoggedIn = false
    settings: IUserSettings = {
        groep: '',
        jaargroep: Yeargroup.ALLE,
        periodeFilters: {
            competentiePeriode: JaarPeriode.THIS_SCHOOLYEAR,
            oefeningPeriode: OefenenPeriode.THIS_WEEK,
            sociaalPeriode: JaarPeriode.THIS_SCHOOLYEAR,
            toetsPeriode: ToetsenPeriode.THIS_WEEK,
            volgtoetsperiode: JaarPeriode.THIS_SCHOOLYEAR,
        },
        widgetRows: {
            competentiesOpened: false,
            oefeningEnVerwerkenOpened: false,
            sociaalEmtotionalOpened: false,
            toetsenOpened: false,
            volgToetsenOpened: false,
        },
        homepageWidgetsBlurred: {
            resultatenBlurred: false,
            notitiesBlurred: false,
        },
    }
    teachers: IUser[] = []
    regio: number | null = null
}

const getters: GetterTree<UserInfoState, any> = {
    getUser(store) {
        return () => store.currentUser
    },
    getUserSettings(store) {
        return () => store.settings
    },
    getUserSettingsPeriod(store) {
        return () => store.settings.periodeFilters
    },
    getUserSettingsYeargroup(store) {
        return () => store.settings.jaargroep
    },
    getUserSettingsWidgets(store) {
        return () => store.settings.widgetRows
    },
    getTeachers(store) {
        return () => store.teachers
    },
    getRegio(store){
        return () => store.regio
    },
    homepageWidgetsBlurred(store) {
        return () => store.settings.homepageWidgetsBlurred
    },
}

const mutations: MutationTree<UserInfoState> = {
    setUser(state, payload: IUser) {
        state.currentUser = payload
    },
    setUserSettings(state, payload: any) {
        state.settings = { ...state.settings, ...payload }
    },
    setUserSettingsWidgets(state, payload: any) {
        state.settings.widgetRows = { ...state.settings.widgetRows, ...payload }
    },
    isLoggedIn(state, payload: boolean) {
        state.isLoggedIn = payload
    },
    setOnboardingFinished(state) {
        state.currentUser.onboardingAfgerond = true
    },
    setRegio(state, payload: number) {
        state.regio = payload
    },
    setHomepageWidgetsBlurred(state, payload: IHomepageWidgetsBlurred) {
        state.settings.homepageWidgetsBlurred = payload
    },
    updateHomepageWidgetsBlur(state, payload: { key: keyof IHomepageWidgetsBlurred; value: boolean }) {
        state.settings.homepageWidgetsBlurred[payload.key] = payload.value
    },
}

const actions: ActionTree<UserInfoState, any> = {
    checkAuth({ commit }) {
        return new Promise((resolve, reject) => {
            userService
                .checkAuth()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setUser', data.data as IUser)
                        commit('isLoggedIn', true)
                        resolve(data)
                    }
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `U bent niet ingelogd. Error: ${e.message}`,
                    })
                    commit('isLoggedIn', false)
                    reject(e)
                })
        })
    },
    loginUser({ commit }) {
        return new Promise((resolve, reject) => {
            userService
                .getUser()
                .then((user) => {
                    commit('setUser', user)
                    resolve(user)
                })
                .catch((e: AxiosError) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    async goToFeedbackPortal({ state }, customUrl?: string) {
        return new Promise((resolve, reject) => {
            try {
                const name = [state.currentUser.voornaam, state.currentUser.tussenvoegsel, state.currentUser.achternaam].filter(Boolean).join(' ')
                const user: UpvotyUser = {
                    id: state.currentUser.id.toString(),
                    name: name,
                    email: state.currentUser.email,
                }
                createUpvotyToken(user).then((token) => {
                    window.open('https://feedback.momento.nl/front/handleSSO/' + token + `/?redirectUrl=${customUrl}`, '_blank')
                    resolve(null)
                })
            } catch (e) {
                console.warn(e)
                notify({
                    group: 'requests',
                    title: 'Foutmelding',
                    type: 'error',
                    text: `Er ging iets mis met het aanmaken van de SSO token voor upvoty. Error: ${e}`,
                })
                reject(e)
                //window.open('https://feedback.momento.nl/b/suggesties/', '_blank');
            }
        })
    },
    getUserSettings({ commit }) {
        return new Promise((resolve, reject) => {
            userService
                .getUserSettings()
                .then((settings) => {
                    commit('setUserSettings', settings)
                    resolve(settings)
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    setUserSettings({ commit, state }) {
        return new Promise((resolve, reject) => {
            userService
                .setUserSettings(state.settings)
                .then((settings) => {
                    commit('setUserSettings', settings)
                    resolve(settings)
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    setUserSettingsGroup({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            commit('setUserSettings', payload)
            userService
                .patchUserSettingsGroup(state.settings.groep)
                .then((settings) => {
                    resolve(settings)
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    setUserSettingsYeargroup({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            commit('setUserSettings', payload)
            userService
                .patchUserSettingsYeargroup(state.settings.jaargroep)
                .then((settings) => {
                    resolve(settings)
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    setUserSettingsPeriod({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            commit('setUserSettings', payload)
            userService
                .patchUserSettingsPeriod(state.settings.periodeFilters)
                .then((settings) => {
                    resolve(settings)
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    setUserSettingsWidgets({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            const newWidgetSettings = { ...state.settings.widgetRows, ...payload }
            const widgetsUnchanged = isEqual(state.settings.widgetRows, newWidgetSettings)
            if (widgetsUnchanged) {
                resolve(true)
            } else {
                commit('setUserSettingsWidgets', payload)
                userService
                    .patchUserSettingsWidgets(state.settings)
                    .then((settings) => {
                        resolve(settings)
                    })
                    .catch((e: Error) => {
                        notify({
                            group: 'requests',
                            title: 'Foutmelding',
                            type: 'error',
                            text: `Er is iets misgegaan. Error: ${e.message}`,
                        })
                        reject(e)
                    })
            }
        })
    },
    getTeachers({ state }) {
        return new Promise((resolve, reject) => {
            settingsService.getLeerkrachten().then((response) => {
                state.teachers = response.data
                resolve(state.teachers)
            })
        })
    },
    getRegio({ state }) {
        return new Promise((resolve, reject) => {
            onboardingService.getSelectedRegio().then((data) => {
                state.regio = data.data as number | null
                resolve(state.regio)
            })
        })
    },
}

export default {
    state: new UserInfoState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}

import { ActionTree, GetterTree, MutationTree } from 'vuex'

import { ActivityType } from '@/enums/ActivityType'
import { convertUTCtoLocal } from '@/helpers/convertTimezone'
import { ILesdag } from '@/interfaces/ILesdag'
import { ILesdagSpeciaal } from '@/interfaces/ILesdagSpeciaal'
import { ILabel } from '@/interfaces/IPlanning'
import { yourDayService } from '@/services/LesdagenService'
import { notify } from '@kyvg/vue3-notification'

class LesdagState {
    currentLesdag = {
        start: new Date('01-01-1980'),
        end: new Date('01-01-1980'),
    }
    lesdag: ILesdag[] = []
    lesdagSpeciaal: ILesdagSpeciaal[] = []
    lesdagLoading = false
}

const getters: GetterTree<LesdagState, any> = {
    lesdag(store) {
        return () => store.currentLesdag
    },
    getLesdag(store): ILesdag[] {
        return () =>
            store.lesdag.filter(
                (dag, index, array) =>
                    index ===
                    array.findIndex((t) => {
                        return new Date(t.activiteit.datumTijd).getTime() === new Date(dag.activiteit.datumTijd).getTime() && t.activiteit.blockId === dag.activiteit.blockId
                    })
            )
    },
    getLesdagSpeciaal(store): ILesdagSpeciaal[] {
        return () => store.lesdagSpeciaal
    },
    les: (store) => (id: number) => {
        return store.lesdag.find((les) => les.activiteit.id === id) || null
    },
    availableYeargroupsLesdag(state, getters, rootstate) {
        return () => rootstate.labels.alleJaargroepenLabels.filter((groep: ILabel) => state.lesdag.find((planitem) => planitem.activiteit.jaargroepLabelId === groep.labelId))
    },
    lesdagLoading(store): boolean {
        return () => store.lesdagLoading
    },
}

const mutations: MutationTree<LesdagState> = {
    setLesdagTijd(state, payload: { start: Date; end: Date }) {
        state.currentLesdag = payload
    },
    setLesdag(state, payload: ILesdag[]) {
        state.lesdag = payload
    },
    setLesdagenSpeciaal(state, payload: ILesdagSpeciaal[]) {
        state.lesdagSpeciaal = payload
    },
    setLesdagLoading(state, payload: boolean) {
        state.lesdagLoading = payload
    },
}

const actions: ActionTree<LesdagState, any> = {
    getLesdagen({ commit, dispatch, rootState, state }) {
        return new Promise((resolve, reject) => {
            const date = new Date(state.currentLesdag.start)
            date.setHours(12)
            yourDayService
                .getLesdagen(rootState.groups.selectedGroup.id, date.toISOString())
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setLesdag', data.data as ILesdag[])
                        if (import.meta.env.PROD) {
                            const trackingObj: any = {
                                stamgroep: rootState.groups.selectedGroup.naam,
                            }

                            data.data.forEach((item: ILesdag) => {
                                const titel = item.activiteit.naamVak ?? item.activiteit.naamBasisActiviteit
                                const methodeId = item.activiteit.methodeId ?? 'eigen_invulling'
                                if (titel) {
                                    const naam = `Invulling_${titel.replaceAll(' ', '_')}`
                                    const methode = `Methode_${titel.replaceAll(' ', '_')}`
                                    if (item.activiteit.activiteitType === ActivityType.EigenInvulling || item.activiteit.activiteitType === ActivityType.ZelfBedachtMetEigenInvulling || (item.activiteit.activiteitType === ActivityType.ZelfBedacht && !item.activiteit.lesplanningTemplateId)) {
                                        trackingObj[naam] = methodeId
                                    } else {
                                        trackingObj[naam] = item.activiteit.methodeBlok && item.activiteit.lesId ? `${item.activiteit.methodeBlok}, ${item.activiteit.lesId}` : null
                                    }
                                    if (trackingObj[methode]) {
                                        if (typeof trackingObj[methode] === 'number' || typeof trackingObj[methode] === 'string') {
                                            if (trackingObj[methode] !== methodeId) {
                                                trackingObj[methode] = [trackingObj[methode], methodeId]
                                            }
                                        } else {
                                            if (!trackingObj[methode].find((m: number | string) => m === methodeId)) {
                                                trackingObj[methode] = [...trackingObj[methode], methodeId]
                                            }
                                        }
                                    } else {
                                        trackingObj[methode] = methodeId
                                    }
                                }
                            })
                            dispatch('appcuesIdentify', trackingObj)
                            trackingObj.evtName = 'invulling'
                            dispatch('appcuesTrack', trackingObj)
                        }
                        resolve(data.data as ILesdag[])
                    }
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    getLesdagenSpeciaal({ commit, rootState, state }): Promise<ILesdagSpeciaal> {
        return new Promise((resolve, reject) => {
            const date = new Date(state.currentLesdag.start)
            date.setHours(12)
            yourDayService
                .getLesdagenSpeciaal(rootState.groups.selectedGroup.id, date.toISOString())
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        const lesdagSpeciaal = {
                            ...data.data,
                            notities: data.data.notities.map(
                                (notitie: any) =>
                                    ({
                                        ...notitie,
                                        planningsDatum: convertUTCtoLocal(new Date(data.planningsDatum)),
                                    }) as ILesdagSpeciaal
                            ),
                        }
                        commit('setLesdagenSpeciaal', lesdagSpeciaal)
                        resolve(lesdagSpeciaal)
                    }
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    getAfspraakdetails({ commit, rootState }, id) {
        return new Promise((resolve, reject) => {
            yourDayService
                .getAfspraakDetails(rootState.groups.selectedGroup.id, id)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setLesdagenSpeciaal', data.data as ILesdagSpeciaal[])
                        resolve(data.data as ILesdagSpeciaal[])
                    }
                })
                .catch((e: Error) => {
                    reject(e)
                })
        })
    },
}

export default {
    state: new LesdagState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}
